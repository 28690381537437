import React, { useContext } from 'react';
import io from 'socket.io-client';

const SocketContext = React.createContext(null);

export const SocketProvider = (props) => {
    // TODO Changer pour le déploiement
    // const value =  io("http://192.168.1.203:8081/", { transports: ['websocket'] });
    const value =  io("https://kfet.vrai-studio.com/", { transports: ['websocket'] });

    return(
        <SocketContext.Provider value={value}>
            {props.children}
        </SocketContext.Provider>
    )
}

export const useSocket = () => {
    return useContext(SocketContext);
}