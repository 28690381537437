import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './Styles/reset.css';
import './Styles/animations.css';
import './Styles/main.css';

import AuthProvider from "./store/auth.provider";
import {SocketProvider} from './store/socket.provider';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const Layout = React.lazy(() => import('views/pages/layouts/Layout'));

class App extends Component {

  render() {
    return (
      <SocketProvider>
        <AuthProvider>
          <BrowserRouter>
              <React.Suspense fallback={loading}>
                  <Layout />
                  {/* <Route path="/" render={props => <Layout {...props}/>} /> */}
              </React.Suspense>
          </BrowserRouter>
        </AuthProvider>
      </SocketProvider>
    );
  }
}

export default App;
