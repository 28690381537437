import React, { createContext, useState } from "react";

export const InterfaceContext = createContext({});

const InterfaceProvider = ({ children }) => {
    const [interfaceView, setInterfaceView] = useState({
        sidebarShow: 'responsive',
        sidebarMinize: false,
        footerShow: true
    });

    // const toggleSidebar = () => {
    //     const val = [true, 'responsive'].includes(interfaceContext.interfaceView.sidebarShow) ? false : 'responsive'
    //     interfaceContext.setInterfaceView({...interfaceContext.interfaceView, sidebarShow : val});
    // }
    // const toggleSidebarMobile = () => {
    //     const val = [false, 'responsive'].includes(interfaceContext.interfaceView.sidebarShow) ? true : 'responsive'
    //     interfaceContext.setInterfaceView({...interfaceContext.interfaceView, sidebarShow : val});
    // }

    return (
        <InterfaceContext.Provider value={{ interfaceView, setInterfaceView }}>
            {children }
        </InterfaceContext.Provider>
    );
}

export default InterfaceProvider;
