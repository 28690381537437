// store/auth.provider.js
import React, { createContext, useEffect, useState } from "react";
import { API, actionGetAPI } from "controllers/Api.controller";

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({ _id : "", email : ""});
  useEffect(() => {
    if(user._id === ""){
      actionGetAPI(API.AUTH, "signin", (data) => {
        if(typeof data._id !== "undefined"){
          changeUser(data);
        }
      })
    }
  }, [user])

  const changeUser = (data) => {
    // console.log(data);
    setUser({
      _id : data._id,
      email : data.email,
    });
  }

  const value = {
    user : user,
    setUser : changeUser
  }

  // console.log(value);

  return (
    <AuthContext.Provider value={value}>
      {children }
    </AuthContext.Provider>
  );
}

export default React.memo(AuthProvider);