import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import InterfaceProvider from './store/interface.provider';

ReactDOM.render(
  <InterfaceProvider>
    <App/>
  </InterfaceProvider>, 
  document.getElementById('root')
);